.navbar {
    width: 100%;
    background-color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    -webkit-box-shadow: 0 1px 2px rgb(202, 202, 202);
    -moz-box-shadow: 0 1px 2px rgb(202, 202, 202);
    box-shadow: 0 1px 2px rgb(202, 202, 202);
    z-index: 1201;
    position: fixed;
}

.navbar .inner {
    padding: 0 10px;
    width: 100%;
    height: 100%;
    margin: auto;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flex {
    display: flex;
    align-items: center;
}


