/* Core stylings*/

body {
  margin: 0;
  font-family: "nunito"; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100% !important;
  overflow-x: hidden !important;
  
}

#root {
    position: relative;
    overflow-x: hidden;
    height: 100vh;
    display: flex;
}


#brevo-conversations {
  bottom: 50px !important;
  z-index: 200 !important;
}

a {
  color: #7920f5ff;
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div {
    -webkit-tap-highlight-color:transparent;
}

* {
  box-sizing: border-box;
}


h2 {
  font-size: 1.5em;
    margin: 10px;
}

.icon {
  transition: all 0.25s ease-in-out;

  height: 30px;
  cursor: pointer;
  opacity: 0.2;

  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
    -o-filter: grayscale(100%);
   -ms-filter: grayscale(100%);
       filter: grayscale(100%); 
}

.icon:hover {
  opacity: 1;
  filter: none;
}




*::-webkit-scrollbar-track {
	border-radius: 10px;
    width:15px;
}

*::-webkit-scrollbar
{
	width: 15px;
	border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.055);
}

*::-webkit-scrollbar-thumb
{
	border-radius: 10px;
    width: 16px;
	background-color: rgba(0, 0, 0, 0.123);
}
*::-webkit-scrollbar-thumb:hover
{
	border-radius: 10px;
    width: 16px;
	background-color: rgba(0, 0, 0, 0.253);
}

input[type=text]::-ms-clear {  display: none; width : 0; height: 0; }
input[type=text]::-ms-reveal {  display: none; width : 0; height: 0; }
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }



.marker {width:0; height:0;}

.marker span {
  display:flex;
  justify-content:center;
  align-items:center;
  box-sizing:border-box;
  width: 30px;
  height: 30px;
  color:#fff;
  background: #7920f5;
  border:solid 2px;
  border-radius: 0 70% 70%;
  box-shadow:0 0 2px #000;
  cursor: pointer;
  transform-origin:0 0;
  transform: rotateZ(-135deg);
}

.marker b {transform: rotateZ(135deg)}
